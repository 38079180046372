@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css";


html,body,#root{
  height: 100%; 
}


.app{
    background-color: white;
    height: 100%;
}
.background-dark{
  background-image:linear-gradient(120deg, rgba(0, 0, 0, 0.94) 0%, rgba(56, 34, 56, 0.93) 120%);
}

.logoBig div{
  background-image:linear-gradient(213deg, rgb(243 136 40 / 84%) 0%, rgb(179 54 179 / 93%) 120%) !important;
}

.instagram{  
  background: #f09433; 
background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  }

.channelFilterIcon>div{
  transition: margin 500ms;
  margin-top: -10px;
}
.channelFilterIcon:hover > div {
  transition: margin 500ms;
  margin-top: 5px;
}

.menuItem{
  font-size: 16pt  !important;
  margin: 10px  !important;
  color:  rgb(75, 55, 75) !important
}
.menuItem:hover{
  font-size: 16pt  !important;
  margin: 10px  !important;
  color:  rgb(112, 51, 112) !important
}

.menuItemActive, .menuItem:active{
  font-size: 16pt  !important;
  font-weight: 900 !important;
  margin: 10px  !important;
  color:  rgb(75, 55, 75) !important
}

mark.title {
  background-color: rgba(0, 0, 0,0.8);
  color: rgb(255, 255, 255);
}

mark.box {
  background-color: rgba(244, 244, 244, 0.8);
  color: rgb(0, 0, 0);
  padding: 0%;
}

.gradient{
  background-image:linear-gradient(213deg, rgb(243 136 40 / 84%) 0%, rgb(179 54 179 / 93%) 120%);
}

/* div.stackedBox {
  border-radius:15px;
  background-color:white;
  position: absolute;
  z-index: 10;
  padding: 15px;  
  width: 96%;
  left: 2%;
  bottom:-5px;
  height: -25px;
  border-radius: 24px;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 40%), 0 4px 8px 0 rgb(0 0 0 / 40%);

} */


.replySidebox{  
  margin-right: -15px;
}
.lastReply{
  
  
  max-height: 50px;
  
}

.reply{
  margin-left: 10px;
  
  border-left:#101010 5px solid ;
  
}
.sidebox{
  margin-right: -20px;
  margin-top:10px;
  
}
.stackedBox{
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  margin-top: 10px;
  box-shadow:	0 0.025rem 0.25rem rgba(0, 0, 0, .3);
   
}

.stackedBoxEnd{
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  position: relative;	
	background-color: #fff;
	padding: 0.5em 0.8em;
  margin-left: 15px;
  z-index:5000;
	font-size: 0.9em;
  left:0px;
  right:0px;
  
	
  box-shadow:	0 0.025rem 0.25rem rgba(0, 0, 0, .3), 0 0.425rem 0.25rem rgba(0, 0, 0, .2);
}

.stackedBox, .stackedBoxReply, .stackedBoxMyReply {
	position: relative;	
	background-color: #fff;
	padding: 0.5em 0.8em;
  margin-left: 15px;
  z-index:5000;
	font-size: 0.9em;
  width: auto;
  left:0px;
  right:0px;
  
	
  
}
.stackedBoxMyReply{
  margin-left: 80px;
}

.stackedBoxReply, .stackedBoxMyReply
{
  background-color: #fbf3f3;
  left:0px;
  width: 100%;
  /* border:1px solid gray; */
  filter: drop-shadow(0px 0.5px 1px rgba(0, 0, 0, 0.5));
  
}


.stackedBox::before {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	bottom: 100%;
	left: -12px;  
	top:10px;
	width: 0;
height: 0;
border-style: solid;
border-width: 9.5px 15px 9.5px 0;
border-color: transparent rgb(255, 255, 255) transparent transparent;
	filter: drop-shadow(-5px 3px 3px rgba(0, 0, 0, 0.2));

}

.stackedBoxReply::before {
content: '';
position: absolute;
width: 0;
height: 0;
bottom: 100%;
left: -18px;  

top:0px;
width: 0;
height: 0;
border-style: solid;
border-width: 0.5px 18px 15.5px 0;
border-color: transparent #f6f6f6 transparent transparent;
filter: drop-shadow(-1px -0px 1px rgba(0, 0, 0, 0.2));

}

.stackedBoxMyReply::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  bottom: 100%;
  right: -18px;  
  
  top:0px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15.5px 18px 15.5px 0;
  border-color: #f6f6f6   transparent transparent transparent ;
  filter: drop-shadow(2px 0.5px 1px rgba(0, 0, 0, 0.2));
  
  }


div.stackedBox1 {
  position: absolute;
  animation: .1s ease-out 0s 1 slideInFromTop;
  transition: bottom 500ms;
	height: 15px;
	bottom:-7px;
	left: 5.5px;
  right:30px;
	margin-left: 35px;
  
	z-index:10;
	background-color: rgb(255, 255, 255);
	/* padding: 0.9em 0.8em; */
	font-size: 1.25em;
	border-radius: 0.3rem;
  box-shadow:	0 0.25rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
}


/* div.stackedBox1:hover {
  transition: bottom 1s ease 0s;
  bottom:1px;
}
div.stackedBox1:hover::after{
  transition: bottom 1s ease 0s;
  bottom:6px;
} */

.commentBox:hover div.stackedBox1{
  transition: bottom 0.5s ease 0s;
  
  bottom:-12px;
}

.commentBox:hover div.stackedBox1::after{
  transition: bottom 0.5s ease 0s;
  background-color: rgba(255, 255, 255,1);
  bottom:8px;
}

.commentBox div.stackedBox1::after{
  transition: bottom 0.5s ease 0s;
  background-color: rgba(255, 255, 255,1);
  bottom:6px;
}


@keyframes slideInFromTop {
  0% {
    /* transform: translateY(-10%) ;  */
    margin-top:-60px;
    
  }
  100% {
    /* transform: translateY(0); */
    margin-top:-0px;
  }
}

@keyframes slideInFromTop2 {
  0% {
    transform: translateY(-50%) ;
    /* margin-top:-60px; */
    
  }
  100% {
    transform: translateY(0); 
    /* margin-top:-0px; */
  }
}
@keyframes slideInFromTop3 {
  0% {
    transform: translateY(-150%) ;
    /* margin-top:-60px; */
    
  }
  80% {
    transform: translateY(-150%) ;
    /* margin-top:-60px; */
    
  }
  100% {
    transform: translateY(0); 
    /* margin-top:-0px; */
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-150%);
  }
  50% {
    transform: translateX(-150%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(+150%);
  }
  90% {
    transform: translateY(+150%);
  }
  100% {
    transform: translateX(0);
  }
}

div.animate .imgBox{
  animation: 0.8s ease-out 0s 1 slideInFromRight;
  
}
div.animate .likeBox{
  animation: 0.9s ease-out 0s 1 slideInFromLeft;
}

div.animate .commentsOverview{
  animation: 2.2s linear 0s 1 slideInFromBottom;
  
}



div:focus {
  outline: 0;
}


div.stackedBoxExpanded {
  position: relative;
  z-index: 1;
  /* height: "100%"; */
  animation: .1s ease-out 0s 1 slideInFromTop;
  transition: bottom 500ms;
	/*bottom:-15px;*/
	left:0x;
  right:0px;
	margin-left: 5px;
  margin-right: 0px;
  margin-top:-10px;
  padding: 15px;
  /* margin-bottom: -100px; */
  /* background-color: rgba(219, 54, 54, 0.993); */
  
  box-shadow:none;
}

div.stackedBoxExpanded > div {
  animation: 0.4s ease-out 0s 1 slideInFromTop;
  transition: bottom 500ms;
	bottom:5px;

}

@keyframes slideBackToTop {
  0% {
    /* transform: translateY(0); */
    margin-top:0;
  }
  100% {
    /* transform: translateY(-10%) ;  */
    margin-top:-100px;
    
  }
}

div.stackedBox1hoveCollapsing {
  margin-left: -15px;
  
}


.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@keyframes slideBackToRight {
  
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(150%);
  }
  100% {
    transform: translateX(150%);
  }
}

div.stackedBox1hoveCollapsing .userAvatar{
  background-color: #cc2366;
  animation: 1.3s linear 0s 1 slideBackToRight;
  margin-top: 5px;
  margin-bottom: 10px;
}
div.stackedBox1hoveCollapsing >div  {
  animation: 0.8s ease-in-out 0s 1 slideBackToTop;
  margin-left: 15px;

}
@keyframes slideInFromRight {
  0% {
    transform: translateX(+150%);
  }
  50% {
    transform: translateX(+150%);
  }
  100% {
    transform: translateX(0);
  }
}
div.stackedBoxExpanded .userAvatar{
  /* margin-left: -50px; */
  animation: 0.4s ease-in 0s 1 slideInFromRight;
  margin-top: 5px;
  margin-bottom: 10px;
}

div.s1tackedBox1hover::after {
  background-color: rgba(255, 255, 255, 0);
  border:none;
  box-shadow:none;
}
div.stackedBox1::after {
  content: '';
	position: absolute;
	
	left:-8px;
  right:-8px;
	bottom:15px;
	/*width: 89%;*/
	z-index:5;
	background-color: rgb(255, 255, 255);
	padding: 0.5em 0.8em;
	border-radius: 0.4rem;
  box-shadow:	0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
height: 0;
border-style: solid;
border-width: 12.5px 20px 12.5px 0;
border-color: rgb(255, 255, 255) ;
}


div.stackedBox2{
  border-radius:15px;
  background-color:white;
  position: relative;
  z-index: 2;
  
  width: 94%;
  left: 3%;
  bottom:50px;
  height: -25px;
  
}



.no-scrollbar::-webkit-scrollbar {
  display: none;
}

div.fadeOutRight{
  background-image:linear-gradient(-90deg, rgb(255,230,238), rgba(255, 230,238,0));
   z-index:100;
   position:absolute;
   right:0;
   height:100%;
   width:25px
}

div.fadeOutLeft{
  background-image:linear-gradient(90deg, rgb(255,230,238), rgba(255, 230,238,0));
   z-index:100;
   position:absolute;
   left:0;
   height:100%;
   width:25px
}

div.SideNavigation{
  border-right: 2px solid rgba(0,0,0,0.1);
}

.searchBoxInput{
  width:auto ;
  border:none;
  background:transparent;
  outline: none;
  color:rgb(72, 72, 72);
  font-weight: 700;
}
input:focus{
  outline: none;
  background:rgba(255,255,255,0,5);
}

label {
  cursor: pointer;
  color: #6c6c6c;
  display: block;
  font-size: 1.2em;
}
label input[type=checkbox] + .label-text:hover:before {
  color: #e43993;
}
label input[type=checkbox] {
  display: none;
}
label input[type=checkbox] + .all:before {
  content: "" !important;
}
label input[type=checkbox] + .label-text:before {
  content: "";
  color: #57575771;
  font-family: "fontAwesome";
  line-height: 1;
  width: 1em;
  display: inline-block;
  margin-right: 2px;
}
label input[type=checkbox]:checked + .label-text:before {
  content: "";
  color: #e43993;
  animation: tick 150ms ease-in;
}
label input[type=checkbox]:disabled + .label-text:before {
  content: "";
  color: #6c6c6c;
}

@keyframes tick {
  0% {
    transform: scale(0);
  }
  90% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}