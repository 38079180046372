.commentBox{
    background-color: antiquewhite;
    border-radius: 0px 15px 15px 15px;
    margin: 5px;
    padding: 5px 10px;
}

.authorsCommentBox{
    background-color: rgb(216, 216, 216) !important;
    border-radius: 15px 0px 15px 15px !important;
    margin: 5px 0px 5px 25px;
    justify-self: flex-end  !important;;
    align-self: flex-end  !important;;
    padding: 5px 10px;
}

