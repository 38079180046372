.pageImgSmall{
    overflow:hidden;
    border-radius: 15px;
    margin: 2px;
    height: 30px;
    width: 30px;

}

.accountName{
    white-space: nowrap; 
    
}